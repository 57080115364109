import React, { useEffect, Fragment } from "react";

import { Switch, Route, withRouter } from "react-router-dom";

// Redux
import store from "./store";

// component
import Login from "./components/Login";
import PrivateRoute from "./routing/PrivateRoute";
import Master from "./components/Master";

import ScrollToTop from "./components/ScrollToTop";

//actions
import { loadUser } from "./redux/auth/action";

const App = ({ isAuthenticated, location }) => {
	useEffect(() => {
		store.dispatch(loadUser());
	}, []);

	return (
		<Fragment>
			<ScrollToTop />
			<Switch>
				<Route exact path='/login'>
					<Login />
				</Route>

				<PrivateRoute path='/'>
					<Master />
				</PrivateRoute>
			</Switch>
		</Fragment>
	);
};

export default withRouter(App);
