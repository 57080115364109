export const LIST_BOOK_REVIEW = 'LIST_BOOK_REVIEW';
export const CREATE_BOOK_REVIEW = 'CREATE_BOOK_REVIEW';
export const SHOW_BOOK_REVIEW = 'SHOW_BOOK_REVIEW';
export const UPDATE_BOOK_REVIEW = 'UPDATE_BOOK_REVIEW';
export const DELETE_BOOK_REVIEW = 'DELETE_BOOK_REVIEW';
export const ADD_DELETE = 'ADD_DELETE';
export const CHECK_ALL = 'CHECK_ALL';
export const LIST_CATEGORY = 'LIST_CATEGORY';
export const CHECK_INPUT_ITEM = 'CHECK_INPUT_ITEM';
export const DATA_REMOVE_BOOK_REVIEW = 'DATA_REMOVE_BOOK_REVIEW';
export const LIST_BOOK_REVIEW_CATEGORY = 'LIST_BOOK_REVIEW_CATEGORY';
export const CREATE_REVIEW = 'CREATE_REVIEW';