export const LIST_CATEGORY = 'LIST_CATEGORY';
export const CREATE_CATEGORY = 'CREATE_CATEGORY';
export const SHOW_CATEGORY = 'SHOW_CATEGORY';
export const UPDATE_CATEGORY = 'UPDATE_CATEGORY';
export const DELETE_CATEGORY = 'DELETE_CATEGORY';
export const ADD_DELETE = 'ADD_DELETE';
export const CHECK_ALL = 'CHECK_ALL';
export const FILTER = 'FILTER';
export const ASSIGN = 'ASSIGN';

export const LIST_CATEGORY_VIDEO = 'LIST_CATEGORY_VIDEO';
export const CREATE_CATEGORY_VIDEO = 'CREATE_CATEGORY_VIDEO';
export const SHOW_CATEGORY_VIDEO = 'SHOW_CATEGORY_VIDEO';
export const UPDATE_CATEGORY_VIDEO = 'UPDATE_CATEGORY_VIDEO';
export const DELETE_CATEGORY_VIDEO = 'DELETE_CATEGORY_VIDEO';
export const UPDATE_META_DATA = 'UPDATE_META_DATA';