export const LIST_BILL = "LIST_BILL";
export const BILL_REPORT = "BILL_REPORT";
export const RESET_BILL_CREATE_STATE = "RESET_BILL_CREATE_STATE";
export const GET_USER_BY_CODE = "GET_USER_BY_CODE";
export const RESET_SEARCH = "RESET_SEARCH";
export const INIT_ITEM = "INIT_ITEM";
export const INIT_ITEM_EDIT = "INIT_ITEM_EDIT";
export const CHANGE_QTY = "CHANGE_QTY";
export const BILL_CREATE = "BILL_CREATE";
export const CLASS_ITEM_COPY = "CLASS_ITEM_COPY";

export const ADD_CLASSROOM = "ADD_CLASSROOM";
export const CHANGE_PAYTYPE = "CHANGE_PAYTYPE";

export const ADMIN_CREATE = "ADMIN_CREATE";

export const SELECT_CLASS = "SELECT_CLASS";
export const DISSELECT_CLASS = "DISSELECT_CLASS";
export const RESET_STATE = "RESET_STATE";

export const SHOW_BILL = "SHOW_BILL";
export const UPDATE_BILL = "UPDATE_BILL";
export const DELETE_BILL = "DELETE_BILL";
export const ADD_DELETE = "ADD_DELETE";
export const CHECK_ALL = "CHECK_ALL";

export const LIST_HISTORY = "LIST_HISTORY";

export const ADD_CLASSROOM_REFUND = "ADD_CLASSROOM_REFUND";
export const DISSELECT_CLASS_REFUND = "DISSELECT_CLASS_REFUND";
export const CHANGE_QTY_CLASS_REFUND = "CHANGE_QTY_CLASS_REFUND";

export const REVENUE_BY_COMPANY = "REVENUE_BY_COMPANY";
export const REVENUE_BY_SUBJECT = "REVENUE_BY_SUBJECT";
export const REVENUE_BY_STAFF = "REVENUE_BY_STAFF";
export const SHOW_BILL_REFUND = "SHOW_BILL_REFUND";
export const DATA_REMOVE_BILL = "DATA_REMOVE_BILL";
