import axios from 'axios';
import * as ActionTypes from './type';
import { initAPI, responseError, notify } from '../../config/api';

export function listAdultEvaluation(data) {
	initAPI();
	return async dispatch => {
		await axios
			.post(`/adult-evalution/list`, data)
			.then(res => {
				notify(res, false);
				if (res.data.code === 200) {
					dispatch({ type: 'PAGING', page: data.page });
					const adultEvals = res.data.data.records;
					const total = res.data.data.totalRecord;
					const limit = res.data.data.perPage;
					dispatch({
						type: ActionTypes.LIST_ADULT_EVALUATION,
						adultEvals,
						total,
						limit,
					});
				}
			})
			.catch(async err => {
				responseError(err);
			});
	};
}

export function createAdultEvaluation(data) {
	initAPI();
	return async (dispatch) => {
		await axios.post(`/adult-evalution/create`, data)
			.then((res) => {
				notify(res);
				if (res.data.code === 200) {
					let adultEval = res.data.data;
					dispatch({
						type: ActionTypes.CREATE_ADULT_EVALUATION,
						adultEval,
						redirect: true,
					})
				}
			}).catch(async (err) => {
				responseError(err);
			})
	}
}

export function addDelete(id, mode = 'deleteone') {
	return (dispatch) => {
		dispatch({ type: 'ADD_DELETE', id, mode });
	};
};

export function showAdultEvaluation(id) {
	return async dispatch => {
		const data = {
			id
		};
		await axios.post(`/adult-evalution/detail`, data)
			.then(res => {
				notify(res, false);
				if (res.data.code === 200) {
					let adultEval = res.data.data;
					dispatch({ type: ActionTypes.SHOW_ADULT_EVALUATION, adultEval });
				}
			})
			.catch(async err => {
				responseError(err);
			});
	}
}

export function updateAdultEvaluation(params) {
	initAPI();
	return async (dispatch) => {

		await axios.post(`/adult-evalution/update`, params)
			.then((res) => {
				notify(res);
				if (res.data.code === 200) {
					let adultEval = res.data.data;
					dispatch({
						type: ActionTypes.UPDATE_ADULT_EVALUATION,
						adultEval,
					})
				} else {
					console.log('failure!');
				}
			})
	}
}

export function checkInputItem(id, mode = "") {
	initAPI();
	return (dispatch) => {
		dispatch({
			type: ActionTypes.CHECK_INPUT_ITEM,
			id,
			mode
		})
	}
}
export function addDataRemoveAdultEval(data) {
	initAPI();
	return (dispatch) => {
		dispatch({
			type: ActionTypes.DATA_REMOVE_ADULTEVAL,
			dataRemoveAdultEval: data
		})
	}
}

export function onDeleteAdultEval(data, isActionRemove = true) {
	initAPI();
	return (dispatch) => {
		axios.post(`/adult-evalution/delete`, data)
			.then((res) => {
				if (res.data.code === 200 || res.data.data.code === 200) {
					notify(res);
					if (isActionRemove) {
						dispatch({
							type: ActionTypes.REMOVE_ADULT_EVALUATION
						})
					}
				}
			})
	}
}