import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import { Radio } from "antd";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { listSubject } from "../../redux/subject/action";
import { listClassroom } from "../../redux/classroom/action";
import { createDocument } from "../../redux/document/action";

class DocumentCreate extends Component {
	constructor(props) {
		super();
		this.state = {
			name: "",
			doc_link: "",
			teacher: "",
			subject_id: "",
			classroom_id: "",
			fileData: "",
			doc_type: "GOOGLE_DRIVE"
		};
	}

	async componentDidMount() {
		const data = {
			limit: 999
		};
		await this.props.listSubject(data);
	}

	_onChange = async e => {
		var name = e.target.name;
		var value = e.target.value;
		this.setState({
			[name]: value
		});

		if (name === 'subject_id') {
			const data = {
				limit: 999,
				subject_id: value
			};
			await this.props.listClassroom(data);
		}
	};

	handleSubmit = async e => {
		e.preventDefault();

		var data = new FormData();

		data.append("name", this.state.name);
		data.append("teacher", this.state.teacher);
		data.append("subject_id", this.state.subject_id);
		data.append("classroom_id", this.state.classroom_id);
		data.append("doc_type", this.state.doc_type);
		if (this.state.doc_type === "GOOGLE_DRIVE") {
			data.append("doc_link", this.state.doc_link);
		} else {
			data.append("files[0]", this.state.fileData);
		}

		await this.props.createDocument(data);

		if (this.props.redirect === true) {
			await this.props.history.push("/document");
		}
	};

	handleSave = async e => {
		e.preventDefault();

		var data = new FormData();

		data.append("name", this.state.name);
		data.append("teacher", this.state.teacher);
		data.append("subject_id", this.state.subject_id);
		data.append("classroom_id", this.state.classroom_id);
		data.append("doc_type", this.state.doc_type);
		if (this.state.doc_type === "GOOGLE_DRIVE") {
			data.append("doc_link", this.state.doc_link);
		} else {
			data.append("files[0]", this.state.fileData);
		}

		await this.props.createDocument(data);
		if (this.props.redirect) {
			await this.setState({
				name: "",
				doc_link: "",
				teacher: "",
				subject_id: "",
				classroom_id: ""
			});
		}
	};

	fetchRows() {
		if (this.props.subjects instanceof Array) {
			return this.props.subjects.map((obj, i) => {
				return (
					<option value={obj._id} key={obj._id}>
						{obj.name}
					</option>
				);
			});
		}
	}

	fetchClassroomRows() {
		if (this.props.classrooms instanceof Array) {
			return this.props.classrooms.map((obj, i) => {
				return (
					<option value={obj._id} key={obj._id}>
						{obj.name}
					</option>
				);
			});
		}
	}

	onChangeHandler = event => {
		if (this.state.doc_type === "PDF") {
			this.setState({
				fileData: event.target.files[0]
			});
		} else {
			this.setState({ doc_link: "" });
		}
	};

	render() {
		return (
			<div>
				<div className="page-content page-container" id="page-content">
					<div className="padding">
						<h2 className="text-md text-highlight sss-page-title">Quản lý tài liệu</h2>
						<div className="row">
							<div className="col-md-10">
								<div className="card">
									<div className="card-header">
										<strong>Thêm tài liệu</strong>
									</div>
									<div className="card-body">
										<div className="form-group row">
											<label className="col-sm-4 col-form-label">
												Tên tài liệu
											</label>
											<div className="col-sm-8">
												<input
													type="text"
													className="form-control"
													name="name"
													onChange={this._onChange}
													value={this.state.name}
												/>
											</div>
										</div>
										<div className="form-group row">
											<label className="col-sm-4 col-form-label">
												Link
											</label>
											<div className="col-sm-8">
												<Radio.Group
													onChange={this._onChange}
													name="doc_type"
													value={this.state.doc_type}
												>
													<Radio
														value={"GOOGLE_DRIVE"}
													>
														Google drive
													</Radio>
													<Radio value={"PDF"}>
														Pdf
													</Radio>
												</Radio.Group>
											</div>
										</div>
										<div className="form-group row">
											<label className="col-sm-4 col-form-label"></label>
											<div className="col-sm-8">
												{this.state.doc_type ===
													"GOOGLE_DRIVE" ? (
													<input
														type="text"
														className="form-control"
														placeholder="Nhập link tài liệu"
														name="doc_link"
														onChange={
															this._onChange
														}
														value={
															this.state.doc_link
														}
													/>
												) : (
													<input
														type="file"
														className="form-control"
														name="fileData"
														onChange={
															this.onChangeHandler
														}
													/>
												)}
											</div>
										</div>
										<div className="form-group row">
											<label className="col-sm-4 col-form-label">
												Giáo viên
											</label>
											<div className="col-sm-8">
												<input
													type="text"
													className="form-control"
													name="teacher"
													onChange={this._onChange}
													value={this.state.teacher}
												/>
											</div>
										</div>
										<div className="form-group row">
											<label className="col-sm-4 col-form-label">
												Môn học
											</label>
											<div className="col-sm-8">
												<select
													className="custom-select"
													value={
														this.state.subject_id
													}
													name="subject_id"
													onChange={this._onChange}
												>
													<option value="">
														-- Chọn môn học --
													</option>
													{this.fetchRows()}
												</select>
											</div>
										</div>
										<div className="form-group row">
											<label className="col-sm-4 col-form-label">
												Lớp học
											</label>
											<div className="col-sm-8">
												<select
													className="custom-select"
													value={
														this.state.classroom_id
													}
													name="classroom_id"
													onChange={this._onChange}
												>
													<option value="">
														-- Chọn lớp học --
													</option>
													{this.fetchClassroomRows()}
												</select>
											</div>
										</div>

									</div>
								</div>
								<div className="form-group row">
									<div className="col-sm-12 text-right">
										<button
											className="btn btn-primary mt-2"
											onClick={this.handleSubmit}
										>
											Lưu
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

function mapStateToProps(state) {
	return {
		documents: state.document.documents,
		subjects: state.subject.subjects,
		redirect: state.document.redirect,
		classrooms: state.classroom.classrooms
	};
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({ listSubject, createDocument, listClassroom }, dispatch);
}

let DocumentCreateContainer = withRouter(
	connect(mapStateToProps, mapDispatchToProps)(DocumentCreate)
);

export default DocumentCreateContainer;
