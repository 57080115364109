import React, { Component, useRef } from "react";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { listChapter } from "../../redux/chapter/action";
import { listSubject } from "../../redux/subject/action";
import { createCategory, uploadImage } from "../../redux/category/action";

import { Editor } from "@tinymce/tinymce-react";

class LessonCreate extends Component {
  constructor(props) {
    super();
    this.state = {
      name: "",
      chapname: "",
      chapter_id: "",
      subject_id: "",
      video_link: "",
      doc_link: "",
      content: "",
      uploadedImages: [],
      total_video_time: 0
    };
  }

  async componentDidMount() {
    const data = {
      limit: 999,
      is_delete: false,
    };
    await this.props.listChapter(data);
    await this.props.listSubject(data);

  }

  _onChange = (e) => {
    var name = e.target.name;
    var value = e.target.value;
    this.setState({
      [name]: value,
    });
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    await this.props.createCategory({
      name: this.state.name,
      content: this.state.content,
      video_link: this.state.video_link,
      doc_link: this.state.doc_link,
      chapter_id: this.state.chapter_id,
      subject_id: this.state.subject_id,
      total_video_time: this.state.total_video_time
    });
    if (this.props.redirect === true) {
      await this.props.history.push("/category");
    }
  };

  handleSave = async (e) => {
    e.preventDefault();
    const data = {
      name: this.state.name,
      content: this.state.content,
      video_link: this.state.video_link,
      doc_link: this.state.doc_link,
      chapter_id: this.state.chapter_id,
      subject_id: this.state.subject_id,
      total_video_time: this.state.total_video_time
    };
    await this.props.createCategory(data);
    if (this.props.redirect === true) {
      await this.setState({
        name: "",
        chapter_id: "",
        subject_id: "",
        video_link: "",
        doc_link: "",
        content: "",
        total_video_time: 0
      });
    }
  };

  fetchRowsSubject() {
    if (this.props.subjects instanceof Array) {
      return this.props.subjects.map((obj, i) => {
        return (
          <option value={obj._id} key={obj._id.toString()}>
            {obj.name}
          </option>
        );
      });
    }
  }

  fetchRowsChapter() {
    if (this.props.chapters instanceof Array) {
      return this.props.chapters.map((obj, i) => {
        if (obj.subject.id === this.state.subject_id) {
          return (
            <option value={obj._id} key={obj._id.toString()}>
              {obj.name}
            </option>
          );
        }
      });
    }
  }

  onChangeHandler = (event) => {
    if (this.state.doc_type === "PDF") {
      this.setState({
        fileData: event.target.files[0],
      });
    } else {
      this.setState({ doc_link: "" });
    }
  };

  _handleEditorChange = (value, editor) => {
    this.setState({ content: value });
  };

  _uploadImageCallBack = async (blobInfo, success, failure) => {
    let file = blobInfo.blob();
    const data = new FormData();
    data.append("files", file);

    await this.props.uploadImage(data);
    if (this.props.image != null) {
      success(this.props.image);
    } else {
      failure("Upload image fail");
    }
  };

  render() {
    return (
      <div>
        <div className="page-content page-container" id="page-content">
          <div className="padding">
            <div className="row">
              <div className="col-md-10">
                <div className="card">
                  <div className="card-header">
                    <strong>Thêm bài giảng mới</strong>
                  </div>
                  <div className="card-body">
                    <div className="form-group row">
                      <label className="col-sm-3 col-form-label">
                        Tên bài giảng
                      </label>
                      <div className="col-sm-9">
                        <input
                          type="text"
                          className="form-control"
                          name="name"
                          onChange={this._onChange}
                          value={this.state.name}
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-sm-3 col-form-label">Môn học</label>
                      <div className="col-sm-9">
                        <select
                          className="custom-select"
                          value={this.state.subject_id}
                          name="subject_id"
                          onChange={this._onChange}
                        >
                          <option value="">-- Chọn môn học --</option>
                          {this.fetchRowsSubject()}
                        </select>
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-sm-3 col-form-label">Chương</label>
                      <div className="col-sm-9">
                        <select
                          className="custom-select"
                          value={this.state.chapter_id}
                          name="chapter_id"
                          onChange={this._onChange}
                        >
                          <option value="">-- Chọn chương --</option>
                          {this.fetchRowsChapter()}
                        </select>
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-sm-3 col-form-label">
                        Link Video
                      </label>
                      <div className="col-sm-9">
                        <input
                          type="text"
                          className="form-control"
                          name="video_link"
                          onChange={this._onChange}
                          value={this.state.video_link}
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-sm-3 col-form-label">
                        Link PDF
                      </label>
                      <div className="col-sm-9">
                        <input
                          type="text"
                          className="form-control"
                          name="doc_link"
                          onChange={this._onChange}
                          value={this.state.doc_link}
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-sm-3 col-form-label">
                        Thời lượng (phút)
                      </label>
                      <div className="col-sm-9">
                        <input
                          type="text"
                          className="form-control"
                          name="total_video_time"
                          onChange={this._onChange}
                          value={this.state.total_video_time}
                        />
                      </div>
                    </div>
                    <div className="row form-group">
                      <label className="col-sm-3 col-form-label">
                        Giới thiệu bài giảng
                      </label>
                      <div className="col-sm-9">
                        <input
                          type="file"
                          id="image-upload-tinymce"
                          multiple
                          name="single-image"
                          style={{ display: "none" }}
                          accept="image/png, image/gif, image/jpeg, image/jpg, image/svg"
                        />
                        <div>f9iqus70xjpuevuxhya9ju46oq2fpl7iha65x3y0fkdrjgte</div>
                        <Editor
                          apiKey={process.env.REACT_APP_API_KEY_TINY}
                          onInit={(evt, editor) => {
                            this._handleEditorChange(
                              this.state.content,
                              editor
                            );
                          }}
                          value={this.state.content}
                          init={{
                            height: 500,
                            menubar: false,
                            images_file_types:
                              "jpeg,jpg,jpe,jfi,jif,jfif,png,gif,bmp,webp",
                            plugins: [
                              "advlist autolink lists link image charmap print preview anchor",
                              "searchreplace visualblocks code fullscreen",
                              "insertdatetime media table paste code help wordcount tiny_mce_wiris",
                            ],
                            external_plugins: {
                              tiny_mce_wiris:
                                "https://www.wiris.net/demo/plugins/tiny_mce/plugin.js",
                            },
                            toolbar:
                              "undo redo | formatselect | " +
                              "bold italic backcolor | image | alignleft aligncenter " +
                              "alignright alignjustify | bullist numlist outdent indent | fontselect |  fontsizeselect |" +
                              "searchreplace visualblocks code fullscreen | " +
                              "lists link advlist insertdatetime media | tiny_mce_wiris_formulaEditor tiny_mce_wiris_formulaEditorChemistry | table paste code removeformat | help",
                            fontsize_formats:
                              "8pt 9pt 10pt 11pt 12pt 14pt 18pt 24pt 30pt 36pt 48pt 60pt 72pt 96pt",
                            content_style:
                              "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                            draggable_modal: true,
                            htmlAllowedTags: [".*"],
                            htmlAllowedAttrs: [".*"],
                            images_upload_handler: (
                              blobInfo,
                              success,
                              failure
                            ) =>
                              this._uploadImageCallBack(
                                blobInfo,
                                success,
                                failure
                              ),
                          }}
                          onEditorChange={this._handleEditorChange}
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-sm-12 text-right">
                        <button
                          className="btn btn-primary mt-2"
                          onClick={this.handleSubmit}
                        >
                          Lưu
                        </button>
                        <button
                          className="btn btn-primary mt-2 ml-2"
                          onClick={this.handleSave}
                        >
                          Lưu & Thêm mới
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    subjects: state.subject.subjects,
    chapters: state.chapter.chapters,
    redirect: state.category.redirect,
    image: state.question.image,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    { listChapter, createCategory, listSubject, uploadImage },
    dispatch
  );
}

let LessonCreateConatainer = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(LessonCreate)
);

export default LessonCreateConatainer;
