import * as ActionTypes from './type';

const initState = {
    documents: [],
    document: null,
    total: 0,
    page: 1,
    limit: 20,
    ids: [],
    checkAll: false,
    redirect: false
}
const reducer = (state = initState, action) => {
    switch (action.type) {
        case ActionTypes.LIST_DOCUMENT:
            return {
                ...state,
                documents: action.documents,
                total: action.total,
                limit: action.limit,
                ids: [],
                checkAll: false,
                redirect: false,
            }
        case 'PAGING':
            return {
                ...state,
                page: action.page
            }
        case ActionTypes.CREATE_DOCUMENT:
            return {
                ...state,
                redirect: action.redirect
            }
        case ActionTypes.SHOW_DOCUMENT:
            return {
                ...state,
                document: action.document
            }
        case ActionTypes.UPDATE_DOCUMENT:
            const arr = state.documents;
            const newArr = arr.filter((ele) => ele._id !== action.document._id);
            newArr.unshift(action.document);
            return {
                ...state,
                documents: newArr,
            }
        case ActionTypes.ADD_DELETE:
            var arrDelete = [];
            var deletes = [];
            arrDelete.push(action.id);
            if (action.mode === 'add') {
                deletes = state.ids.concat(arrDelete);
            } else if (action.mode === 'remove') {
                deletes = state.ids.filter(ele => ele !== action.id);
            } else {
                deletes = arrDelete;
            }
            return {
                ...state,
                ids: deletes
            }
        case ActionTypes.DELETE_DOCUMENT:
            return {
                ...state,

                ids: [],
                checkAll: false
            }
        case ActionTypes.CHECK_ALL:
            const documents = state.documents;
            var deletesAll = [];
            if (action.status) {
                deletesAll = Object.assign([], Array.from(documents, ele => ele._id));
            } else {
                deletesAll = [];
            }
            return {
                ...state,
                checkAll: action.status,
                ids: deletesAll
            }
        case ActionTypes.ADD_DATA_REMOVE_DOCUMENT: 
            return {
                ...state,
                dataRemoveDocument: action.dataRemoveDocument
            }
        default:
            return state;
    }
}

export default reducer;