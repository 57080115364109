import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Moment from "moment";
import { notification } from "antd";
import {
	listSubject,
	deleteSubject,
	addDelete,
	checkAll,
	updateSubject,
	addDataRemoveSubject
} from "../../redux/subject/action";

import HeadingSortColumn from "../HeadingSortColumn";

import Pagination from "react-js-pagination";

import queryString from 'query-string';

class Row extends Component {
	constructor(props) {
		super();
		this.state = {
			check: false,
			is_online: false,
			ordering: 0
		};
	}

	componentDidMount() {
		this.setState({
			ordering: this.props.obj.ordering ? this.props.obj.ordering : 0,
			is_online: this.props.obj.is_online ? this.props.obj.is_online : false
		})
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		if (this.props.check !== nextProps.check) {
			this.setState({
				check: nextProps.check,
			});
		}
	}

	handleCheckBox = (e) => {
		if (e.target.checked) {
			this.props.handleCheckedIds(this.props.obj._id, 'add');
			this.setState({
				check: e.target.checked
			})
		} else {
			this.props.handleCheckedIds(this.props.obj._id, 'remove');
			this.setState({
				check: e.target.checked
			})
		}
	};

	handleChangeOrdering = async e => {
		var name = e.target.name;
		var value = e.target.value;
		await this.setState({
			[name]: value,
		});

		const data = {
			id: this.props.obj._id,
			code: this.props.obj.code,
			name: this.props.obj.name,
			is_online: this.state.is_online,
			support_fb_link: this.props.obj.support_fb_link,
			teacher_id: this.props.obj.teacher.id,
			supporter_id: this.props.obj.supporter.id,
			ordering: this.state.ordering
		};

		await this.props.updateSubject(data);
	};

	handleCheck = async (e) => {
		this.props.onDeleteOne(true);
		this.props.addDataRemoveSubject({
			ids: this.props.obj._id
		})
	}

	render() {
		return (
			<tr className='v-middle table-row-item' data-id={17}>
				<td>
					<label className='ui-check m-0'>
						<input
							type="checkbox"
							name="id"
							className="checkInputItem"
							onChange={this.handleCheckBox}
							value={this.props.obj._id}
						/>{' '}
						<i />
					</label>
				</td>
				<td className='flex'>
					<Link
						className='item-author text-color'
						to={"/subject/" + this.props.obj._id + "/edit"}
					>
						{this.props.obj.name}
					</Link>
				</td>
				<td>{this.props.obj.code}</td>
				<td className="text-left" width={150}>
					<input type="number" className="form-control" name="ordering" value={this.state.ordering} onChange={this.handleChangeOrdering} />
				</td>
				<td>
					<span className='item-amount d-none d-sm-block text-sm'>
						{this.props.obj.updated_at &&
							Moment(this.props.obj.updated_at).format(
								"DD/MM/YYYY HH:mm"
							)}
					</span>
				</td>

				<td className="text-right">
					<div className='item-action'>
						<Link
							data-toggle='tooltip'
							title='Chỉnh sửa'
							className='mr-14'
							to={"/subject/" + this.props.obj._id + "/edit"}
						>
							<img src="/assets/img/icon-edit.svg" alt="" />
						</Link>
						<div
							data-toggle='tooltip'
							title='Xóa'
						>
							<a
								onClick={this.handleCheck}
								data-toggle='modal'
								data-target='#delete-subject'
								data-toggle-class='fade-down'
								data-toggle-class-target='.animate'
							>
								<img src="/assets/img/icon-delete.svg" alt="" />
							</a>
						</div>
					</div>
				</td>
			</tr>
		);
	}
}

class Subject extends Component {
	constructor(props) {
		super();
		this.state = {
			keyword: "",
			limit: "",
			checkAll: false,
			activePage: 1,
			ids: [],
			sort_key: "",
			sort_value: ""
		};
	}

	onChange = (e) => {
		var name = e.target.name;
		var value = e.target.value;
		this.setState({
			[name]: value,
		});
	};

	getData = async (pageNumber = 1) => {
		const params = {
			keyword: this.state.keyword,
			limit: this.state.limit,
			is_delete: false,
			user_group: "STUDENT",
			sort_key: this.state.sort_key,
			sort_value: this.state.sort_value,
		};

		params.page = this.state.page || pageNumber;

		await this.props.listSubject(params);
	};

	async componentDidMount() {
		const url = this.props.location.search;
		let params = queryString.parse(url);

		await this.setState({
			keyword: params.keyword ? params.keyword : "",
			sort_key: params.sort_key ? params.sort_key : null,
			sort_value: params.sort_value ? params.sort_value : null,
		})


		if (this.props.limit) {
			await this.setState({
				limit: this.props.limit,
				checkAll: false,
			});
		}

		this.getData(this.state.activePage);
	}

	onSubmit = async (e) => {

		e.preventDefault();
		let { keyword } = this.state;

		this.props.history.push(`/subject?keyword=${keyword}`);

		await this.getData(1);
	};

	handleChangePage = (pageNumber) => {
		window.scrollTo({ top: 0, behavior: "smooth" });
		this.props.listSubject(this.getData(pageNumber));
	};

	handleChange = async (e) => {
		var name = e.target.name;
		var value = e.target.value;
		await this.setState({
			[name]: value,
		});
		await this.props.listSubject(this.getData());
	};

	fetchRows() {
		if (this.props.subjects instanceof Array) {
			return this.props.subjects.map((object, i) => {
				return (
					<Row
						obj={object}
						key={object._id}
						index={i}
						listSubject={this.props.listSubject}
						handleCheckedIds={this.handleCheckedIds}
						check={this.props.check}
						updateSubject={this.props.updateSubject}
						addDataRemoveSubject={this.props.addDataRemoveSubject}
						onDeleteOne={this.onDeleteOne}
					/>
				);
			});
		}
	}

	onDeleteOne = async (onResetIds) => {
		if (onResetIds) {
			await this.setState({
				ids: []
			})
		}
	}

	handleCheckedIds = async (id, type = '') => {
		const _ids = this.state.ids;
		if (type === 'add') {
			if (_ids.indexOf(id) < 0) {
				_ids.push(id);
			}
		}
		if (type === 'remove') {
			let index = _ids.indexOf(id);
			if (index > -1) {
				_ids.splice(index, 1);
			}
		}

		await this.setState({
			ids: _ids
		})

	}

	handleDelete = async () => {

		let inputs = document.querySelectorAll('.checkInputItem');
		let data = this.props.dataRemoveSubject;

		if (this.state.ids && this.state.ids.length > 0) {
			data = {
				ids: this.state.ids
			};
		}

		await this.props.deleteSubject(data);
		this.props.listSubject(this.getData());

		for (var i = 0; i < inputs.length; i++) {
			inputs[i].checked = false;
		}

		await this.setState({
			ids: []
		})
	};

	UNSAFE_componentWillReceiveProps(nextProps) {
		if (this.props.checkAll !== nextProps.check) {
			this.setState({
				checkAll: nextProps.check,
			});
		}
	}

	handleCheckAll = async (e) => {
		var inputs = document.querySelectorAll('.checkInputItem');
		var flag = false;

		if (e.target.checked) {
			flag = true;
		}

		let _ids = [];
		for (let i = 0; i < inputs.length; i++) {
			inputs[i].checked = flag;
			if (flag) {
				_ids.push(inputs[i].value);
			} else {
				_ids = [];
			}
		}

		await this.setState({
			ids: _ids
		})
	};


	sort = async (event) => {
		const { classList } = event.target;

		const name = event.target.getAttribute("name");

		await this.setState({
			sort_key: name,
			sort_value: this.state.sort_value == 1 ? -1 : 1
		});



		let { keyword, sort_key, sort_value } = this.state;

		this.props.history.push(`/subject?keyword=${keyword}&sort_key=${sort_key}&sort_value=${sort_value}`);

		await this.getData(1);

	}

	render() {
		let displayFrom =
			this.props.page === 1
				? 1
				: (parseInt(this.props.page) - 1) * this.props.limit;
		let displayTo =
			this.props.page === 1
				? this.props.limit
				: displayFrom + this.props.limit;
		displayTo = displayTo > this.props.total ? this.props.total : displayTo;
		return (
			<div>
				<div className='page-content page-container' id='page-content'>
					<div className='padding'>
						<h2 className='text-md text-highlight sss-page-title'>Môn học</h2>
						<div className='toolbar'>
							<div className='btn-group'>
							
							</div>

							<form className='flex' onSubmit={this.onSubmit}>
								<div className='input-group'>
									<input
										type='text'
										className='form-control form-control-theme keyword-custom'
										placeholder='Nhập từ khoá tìm kiếm...'
										name='keyword'
										onChange={this.onChange}
									/>{" "}
									<span className='input-group-append'>
										<button
											className='btn btn-white btn-sm'
											type='submit'
										>
											<span className='d-flex text-muted'>
												<svg
													xmlns='http://www.w3.org/2000/svg'
													width={16}
													height={16}
													viewBox='0 0 24 24'
													fill='none'
													stroke='currentColor'
													strokeWidth={2}
													strokeLinecap='round'
													strokeLinejoin='round'
													className='feather feather-search'
												>
													<circle
														cx={11}
														cy={11}
														r={8}
													/>
													<line
														x1={21}
														y1={21}
														x2='16.65'
														y2='16.65'
													/>
												</svg>
											</span>
										</button>
									</span>
								</div>
							</form>
						</div>

						<div className='row'>
							<div className='col-sm-12'>
								<table className='table table-theme table-row v-middle'>
									<thead className='text-muted'>
										<tr>
											<th width='10px'>
												<label className='ui-check m-0'>
													<input
														type="checkbox"
														name="id"
														onChange={this.handleCheckAll}
													/>{' '}
													<i />
												</label>
												{this.state.ids.length !== 0 && (
													<button
														className="btn btn-icon ml-16"
														data-toggle="modal"
														data-target="#delete-subject"
														data-toggle-class="fade-down"
														data-toggle-class-target=".animate"
														title="Trash"
														id="btn-trash">
														<svg
															xmlns="http://www.w3.org/2000/svg"
															width={16}
															height={16}
															viewBox="0 0 24 24"
															fill="none"
															stroke="currentColor"
															strokeWidth={2}
															strokeLinecap="round"
															strokeLinejoin="round"
															className="feather feather-trash text-muted">
															<polyline points="3 6 5 6 21 6" />
															<path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2" />
														</svg>
													</button>)
												}
											</th>
											<HeadingSortColumn
												name="name"
												content="Tên môn học"
												handleSort={this.sort}
												sort_key={this.state.sort_key}
												sort_value={this.state.sort_value}
											/>
											<HeadingSortColumn
												name="code"
												content="Mã môn học"
												handleSort={this.sort}
												sort_key={this.state.sort_key}
												sort_value={this.state.sort_value}
											/>
											<HeadingSortColumn
												name="ordering"
												content="Thứ tự"
												handleSort={this.sort}
												sort_key={this.state.sort_key}
												sort_value={this.state.sort_value}
											/>
											<HeadingSortColumn
												name="updated_at"
												content="Ngày cập nhật"
												handleSort={this.sort}
												sort_key={this.state.sort_key}
												sort_value={this.state.sort_value}
											/>
											<th className="text-right">
												Thao tác
											</th>
										</tr>
									</thead>
									<tbody>{this.fetchRows()}</tbody>
								</table>
							</div>
						</div>

						<div className='row listing-footer'>
							<div className='col-sm-1'>
								<select
									className='custom-select w-70'
									value={this.state.limit}
									onChange={this.handleChange}
									name='limit'
								>
									<option value='20'>20</option>
									<option value='50'>50</option>
									<option value='100'>100</option>
									<option value='-1'>ALL</option>
								</select>
							</div>
							<div className='col-sm-6 showing-text'>
								{" "}
								Hiển thị từ <b>{displayFrom}</b> đến{" "}
								<b>{displayTo}</b> trong tổng số{" "}
								<b>{this.props.total}</b>
							</div>
							{this.props.total !== 0 ? (
								<div className='col-sm-5 text-right'>
									<Pagination
										activePage={this.props.page}
										itemsCountPerPage={this.props.limit}
										totalItemsCount={this.props.total}
										pageRangeDisplayed={10}
										onChange={this.handleChangePage}
									/>
								</div>
							) : (
								<div className=''>Không có bản ghi nào</div>
							)}
						</div>
					</div>

					<div
						id='delete-subject'
						className='modal fade'
						data-backdrop='true'
						style={{ display: "none" }}
						aria-hidden='true'
					>
						<div
							className='modal-dialog animate fade-down'
							data-className='fade-down'
						>
							<div className='modal-content'>
								<div className='modal-header'>
									<div className='modal-title text-md'>
										Thông báo
									</div>
									<button
										className='close'
										data-dismiss='modal'
									>
										×
									</button>
								</div>
								<div className='modal-body'>
									<div className='p-4 text-center'>
										<p>
											Bạn chắc chắn muốn xóa bản ghi này
											chứ?
										</p>
									</div>
								</div>
								<div className='modal-footer'>
									<button
										type='button'
										className='btn btn-light'
										data-dismiss='modal'
									>
										Đóng
									</button>
									<button
										type='button'
										onClick={this.handleDelete}
										className='btn btn-danger'
										data-dismiss='modal'
									>
										Xoá
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

function mapStateToProps(state) {
	return {
		subjects: state.subject.subjects,
		limit: state.subject.limit,
		page: state.subject.page,
		total: state.subject.total,
		ids: state.subject.ids,
		check: state.subject.checkAll,
		dataRemoveSubject: state.subject.dataRemoveSubject
	};
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators(
		{ listSubject, deleteSubject, addDelete, checkAll, updateSubject, addDataRemoveSubject },
		dispatch
	);
}

let SubjectContainer = withRouter(
	connect(mapStateToProps, mapDispatchToProps)(Subject)
);

export default SubjectContainer;
