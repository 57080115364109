import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Select } from "antd";

import {
  uploadBanner,
  uploadImageSchedule
} from "../../redux/file/action";
import  { pageUpdate, pageDetail } from "../../redux/setting/action";
import BaseHelpers from "../../helpers/BaseHelpers";
import _ from "lodash";
import { data } from "jquery";


const CDN = "https://cdn.luyenthitiendat.vn/";
const { Option } = Select;

class IntroPage extends Component {
  constructor(props) {
    super();
    this.state = {
      banner: "",
      title: "",
      description: "",
      schedules: [],
      selectedIndexSchedule: null
    };
  }

  async componentDidMount() {
    await this.props.pageDetail({
      key: "about"
    })

    if (this.props.contentConfigs) {
      let contentData = this.props.contentConfigs;
      await this.setState({
        banner: contentData.banner ? contentData.banner : "",
        title: contentData.title ? contentData.title : "",
        description: contentData.description ? contentData.description : "",
        schedules: contentData.block_schedule && contentData.block_schedule.length > 0 ? contentData.block_schedule : []
      })
    }
  }

  async UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.bannerImage !== nextProps.bannerImage) {
      await this.setState({
        banner: nextProps.bannerImage
      })
    }

    if (this.props.scheduleImage !== nextProps.scheduleImage) {
      let { selectedIndexSchedule } = this.state;
      let _schedules = [...this.state.schedules];

      let dataSchedules = _schedules.map((item, index) => {
        if (index == selectedIndexSchedule) {
          item.image = nextProps.scheduleImage;
        }
        return item;
      })

      await this.setState({
        schedules: dataSchedules
      })
    }
  }

  _onChange = (e) => {
    var name = e.target.name;
    var value = e.target.value;
    this.setState({
      [name]: value,
    });
  };

  handleSubmit = async (e) => {
    e.preventDefault();

    let { banner, title, description, schedules } = this.state;
    const data = {
      key: "about",
      content_configs: {
        banner: banner ? banner : "",
        title: title ? title : "",
        description: description ? description : "",
        block_schedule: schedules && schedules.length > 0 ? schedules : [],
      }
    };


    this.props.pageUpdate(data)
  };


  onChangeValueSchedule = async (e, _index) => {
    let { name, value } = e.target;

    let _schedules = [...this.state.schedules];

    let returnData = _schedules.map((item, index) => {
      if (index == _index) {
        item[name] = value;
      }
      return item;
    })

    this.setState({
      schedules: returnData
    })

  }

  onChangeBannersImage = async (e) => {
    const files = e.target.files;

    if (files && files.length > 0) {
      const form = BaseHelpers.getFormDataUpload(files, "homepage");
      if (form) {
        this.props.uploadBanner(form);
      }
    }
  }


  handleAddBanner = () => {
    document.getElementById("input-add-banner").click();
  }

  removeBanner = () => {

    this.setState({
      banner: ""
    })
  }





  handleAddSchedule = () => {
    let _schedules = [...this.state.schedules];

    let defaultData = {
      image: "",
      title: "",
      description: ""
    }

    _schedules.push(defaultData);

    this.setState({
      schedules: _schedules
    })
  }

  handleUploadImageSchedule = (index) => {
    this.setState({
      selectedIndexSchedule: index
    })
    document.getElementById("input-upload-schedule").click();
  }
  removeImageSchedule = async (_index) => {
    document.getElementById("input-upload-schedule").value = "";

    let _schedule = [...this.state.schedules];

    let dataRemoveImage = _schedule.map((item, index) => {
      if (index == _index) {
        item.image = "";
      }
      return item;
    })

    this.setState({
      schedules: dataRemoveImage
    })
  }

  onChangeImageSchedule = async (e) => {
    const files = e.target.files;

    if (files && files.length > 0) {
      const form = BaseHelpers.getFormDataUpload(files, "homepage");
      if (form) {
        this.props.uploadImageSchedule(form);
      }
    }
  }

  deleteItemSchedule = async (index) => {

    let _schedule = [...this.state.schedules];
    _schedule.splice(index, 1);

    this.setState({
      schedules: _schedule
    })
  }


  render() {
    const { banner, schedules, auditions } = this.state;
    return (
      <div>
        <div className="page-content page-container setting-intro-page" id="page-content">
          <div className="padding">
            <h2 className='text-md text-highlight sss-page-title'>Cài đặt trang giới thiệu</h2>
            <div className="block-item-content">
              <h3 className="title-block">Banner</h3>
              <input
                onChange={this.onChangeBannersImage}
                type="file"
                name="file"
                accept="image/*"
                // multiple="multiple"
                className="form-control-file d-none"
                id="input-add-banner"
              />
              <div className="block-banner-title-desc">
                <div className="block-avatar block-image">
                  {
                    !banner || banner == ""
                      ?
                      <button type="button" onClick={this.handleAddBanner}>
                        <img src="/assets/img/icon-upload-file.svg" className="mr-10" alt="" />
                        <span>Thêm ảnh</span>
                      </button>
                      :
                      <div className="block-image-overlay">
                        <img
                          id="output"
                          src={this.state.banner}
                          alt="your image"
                          className="image"
                        />
                        <div class="middle">
                          <div class="text" onClick={this.removeBanner}>Xóa</div>
                        </div>
                      </div>
                  }
                </div>
                <div className="form-group mb-0" style={{ minWidth: 350 }}>
                  <div className="text-form-label mb-8">Tiêu đề</div>
                  <input type="text" value={this.state.title} placeholder="Nhập tiêu đề" onChange={this._onChange} className="form-control" name="title" />
                </div>
                <div className="form-group mb-0" style={{ minWidth: 450 }}>
                  <div className="text-form-label mb-8">Mô tả ngắn</div>
                  <input type="text" placeholder="Nhập mô tả" value={this.state.description} onChange={this._onChange} className="form-control" name="description" />
                </div>
              </div>

            </div>

            <div className="block-item-content">
              <h3 className="title-block">Lộ trình ôn thi</h3>


              <input
                onChange={this.onChangeImageSchedule}
                type="file"
                className="form-control-file d-none"
                name="img-schedule"
                id="input-upload-schedule"
              />

              <div className="block-schedule">
                {
                  schedules && schedules.length > 0
                  &&
                  schedules.map((item, index) => {
                    return (
                      <div className="item-schedule">
                        <div className="block-avatar block-image">
                          {
                            !item.image || item.image == ""
                              ?
                              <button type="button" onClick={() => this.handleUploadImageSchedule(index)}>
                                <img src="/assets/img/icon-upload-file.svg" className="mr-10" alt="" />
                                <span>Thêm ảnh</span>
                              </button>
                              :
                              <div className="block-image-overlay">
                                <img
                                  id="output"
                                  src={item.image}
                                  alt="your image"
                                  className="image"
                                />
                                <div class="middle">
                                  <div class="text" onClick={() => this.removeImageSchedule(index)}>Xóa</div>
                                </div>
                              </div>
                          }
                        </div>
                        <div className="form-group">
                          <label className="text-form-label">Tiêu đề</label>
                          <div>
                            <input
                              type="text"
                              className="form-control"
                              name="title"
                              onChange={(e) => this.onChangeValueSchedule(e, index)}
                              value={item.title}
                            />
                          </div>
                        </div>
                        <div className="form-group">
                          <label className="text-form-label">Mô tả</label>
                          <div>
                            <textarea type="text"
                              className="form-control"
                              name="description"
                              onChange={(e) => this.onChangeValueSchedule(e, index)}
                              value={item.description}></textarea>
                          </div>
                        </div>
                        <div className="btn-delete-item" onClick={() => this.deleteItemSchedule(index)}>
                          <span className="mr-8">Xóa</span>
                          <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M15.75 4.5H2.25V6.75C3.07843 6.75 3.75 7.42157 3.75 8.25V11.25C3.75 13.3713 3.75 14.432 4.40901 15.091C5.06802 15.75 6.12868 15.75 8.25 15.75H9.75C11.8713 15.75 12.932 15.75 13.591 15.091C14.25 14.432 14.25 13.3713 14.25 11.25V8.25C14.25 7.42157 14.9216 6.75 15.75 6.75V4.5ZM7.875 8.25C7.875 7.83579 7.53921 7.5 7.125 7.5C6.71079 7.5 6.375 7.83579 6.375 8.25V12C6.375 12.4142 6.71079 12.75 7.125 12.75C7.53921 12.75 7.875 12.4142 7.875 12V8.25ZM11.625 8.25C11.625 7.83579 11.2892 7.5 10.875 7.5C10.4608 7.5 10.125 7.83579 10.125 8.25V12C10.125 12.4142 10.4608 12.75 10.875 12.75C11.2892 12.75 11.625 12.4142 11.625 12V8.25Z" fill="white" />
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M10.0548 1.59092C9.73679 1.52993 9.36726 1.5 9.00033 1.5C8.6334 1.5 8.26386 1.52993 7.94589 1.59092C7.78693 1.62142 7.6313 1.66147 7.4903 1.71422C7.36224 1.76213 7.18757 1.84169 7.03979 1.97956C6.73693 2.26214 6.72049 2.73673 7.00306 3.03959C7.26971 3.32538 7.70733 3.35613 8.0103 3.12126C8.01203 3.12058 8.01391 3.11987 8.01594 3.1191C8.05596 3.10413 8.12548 3.08382 8.22848 3.06406C8.43444 3.02455 8.70685 3 9.00033 3C9.2938 3 9.56621 3.02455 9.77217 3.06406C9.87518 3.08382 9.94469 3.10413 9.98471 3.1191C9.98675 3.11987 9.98863 3.12058 9.99036 3.12126C10.2933 3.35613 10.7309 3.32538 10.9976 3.03959C11.2802 2.73673 11.2637 2.26214 10.9609 1.97956C10.8131 1.84168 10.6384 1.76213 10.5104 1.71422C10.3694 1.66146 10.2137 1.62142 10.0548 1.59092Z" fill="white" />
                          </svg>

                        </div>
                      </div>
                    )
                  })
                }
              </div>
              {
                schedules && schedules.length < 4
                &&
                <div className="btn-add-schedule" onClick={() => this.handleAddSchedule()}>
                  <span className="mr-8">+</span>
                  <span>Thêm lộ trình</span>
                </div>
              }
            </div>


            <div className="block-action-footer">
            <button type="button" className="btn-cancel">
              <img src="/assets/img/icon-arrow-left.svg" alt="" className="mr-14" />
              Hủy bỏ thay đổi
            </button>
            <button type="button" className="btn-submit ml-16" onClick={(e) => this.handleSubmit(e)}>
              Hoàn tất chỉnh sửa
              <img src="/assets/img/icon-arrow-right.svg" alt="" className="ml-14" />
            </button>
          </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    bannerImage: state.file.banner_image,
    scheduleImage: state.file.schedule_image,
    contentConfigs: state.setting.contentConfigs
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    { uploadBanner, uploadImageSchedule, pageUpdate, pageDetail },
    dispatch
  );
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(IntroPage)
);
