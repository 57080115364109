import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createAdultEvaluation } from '../../redux/adultEvaluation/action';

import { Radio } from "antd";

class AdultEvaluationCreate extends Component {
    constructor(props) {
        super();
        this.state = {
            name: '',
            type: 'DANHGIA_PHUHUYNH',
            description: '',
            content: '',
            status: true,
            files: [],
            external_link: '',
            ordering: 1,
        };
    }

    async componentDidMount() {
    }

    _onChange = async e => {
        var name = e.target.name;
        let value = e.target.value;
        if (name === 'files') {
            value = await new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(e.target.files[0]);
                reader.onload = () => {
                    resolve(reader.result);
                }
                reader.onerror = error => reject(error);
            });
            value = [value];
        }
        await this.setState({
            [name]: value,
        });

    };

    handleSubmit = async (type) => {
        var { name, description, content, files, status, type } = this.state;
        const data = {
            name,
            description,
            content,
            files,
            type,
            status
        };

        if (type === 0) {
            console.log('Chưa làm');
        } else {
            await this.props.createAdultEvaluation(data);
            if (this.props.redirect === true && this.props.adultEval) {
                await this.props.history.push('/adult-evaluation');
            }
        }


    };

    render() {
        return (
            <div>
                <div className="page-content page-container" id="page-content">
                    <div className="padding">
                        <h2 className='text-md text-highlight sss-page-title'>
                            Thêm đánh giá
                        </h2>
                        <div className="flex" />
                        <div className="block-adult-create">
                            <div className="row">
                                <div className="col-md-10">
                                    <div className="card">
                                        <div className="card-header">
                                            <strong>Thêm mới đánh giá</strong>
                                        </div>
                                        <div className="card-body">
                                            <div className="form-group row">
                                                <div className="col-sm-6">
                                                    <label className="col-form-label">
                                                        Tên
                                                    </label>
                                                    <div >
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            name="name"
                                                            onChange={this._onChange}
                                                            value={this.state.name}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-sm-6">
                                                    <label className="col-form-label">
                                                        Mô tả
                                                    </label>
                                                    <div >
                                                        <textarea
                                                            type="text"
                                                            className="form-control"
                                                            name="description"
                                                            onChange={this._onChange}
                                                            value={this.state.description}
                                                        />
                                                    </div>
                                                </div>

                                            </div>
                                            <div className="form-group row">
                                                <div className="col-sm-6">
                                                    <label className="col-form-label">
                                                        Nội dung
                                                    </label>
                                                    <div >
                                                        <textarea
                                                            type="text"
                                                            className="form-control"
                                                            name="content"
                                                            onChange={this._onChange}
                                                            value={this.state.content}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-sm-6">
                                                    <label className=" col-form-label">
                                                        Hình ảnh
                                                    </label>
                                                    <div className="">
                                                        <input onChange={this._onChange} type="file" className="form-control-file" name="files" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group row">
                                                <div className="col-sm-6">
                                                    <label className=" col-form-label">
                                                        Chọn loại
                                                    </label>
                                                    <div>
                                                        <select className='form-control' name="type" onChange={this._onChange}>
                                                            <option value="DANHGIA_PHUHUYNH">Đánh giá Phụ Huynh</option>
                                                            <option value="TOP_RANKS">Vinh danh</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group row">
                                                <div className="col-sm-6">
                                                    <label className=" col-form-label">
                                                        Trạng thái
                                                    </label>
                                                    <div>
                                                        <Radio.Group
                                                            onChange={this._onChange}
                                                            name="status"
                                                            value={this.state.status}
                                                        >
                                                            <Radio value={true}>Hiển thị</Radio>
                                                            <Radio value={false}>Ẩn</Radio>
                                                        </Radio.Group>
                                                    </div>
                                                </div>
                                            </div>
                                            
                                            <div className="form-group row">
                                                <div className="col-sm-12 text-right">
                                                    <button
                                                        className="btn btn-primary mt-2"
                                                        onClick={() => this.handleSubmit(0)}>
                                                        Lưu
                                                    </button>
                                                    <button
                                                        className="btn btn-primary mt-2 ml-2"
                                                        onClick={() => this.handleSubmit(1)}>
                                                        Lưu & Thêm mới
                                                    </button>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        adultEval: state.adultEvals.adultEval,
        redirect: state.adultEvals.redirect,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ createAdultEvaluation }, dispatch);
}

let ContainerCreate = withRouter(
    connect(mapStateToProps, mapDispatchToProps)(AdultEvaluationCreate),
);

export default ContainerCreate;
