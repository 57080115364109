import React, { Component } from "react";
import { listSubject } from "../../../redux/subject/action";
import { createChapter, listChapter } from '../../../redux/chapter/action';
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { isEmpty } from "lodash";
import moment from "moment";
import { Radio, Select, DatePicker } from "antd";
import { createCategory, uploadImage, showCategory, updateCategory } from "../../../redux/category/action";
import { listExam } from "../../../redux/exam/action";
import { Editor } from "@tinymce/tinymce-react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
const { Option } = Select;

class ModalEditLesson extends Component {
    constructor(props) {
        super();
        this.state = {
            id: null,
            name: "",
            chapter_id: "",
            subject_id: "",
            classroom_id: props.classroom_id ? props.classroom_id : "",
            content: "",
            is_free: false,
            free_started_at: null,
            free_finished_at: null,
            publish_at: null,
            exam_started_at: null,
            exam_finished_at: null,
            total_video_time: 0,
            video_name: "",
            video_link: "",
            video_duration: "",
            videos: [],
            exam: null,
            exam_id: "",
            exam_doc_link_1: "",
            exam_doc_link_2: ""
        };
    }



    onChange = async (e) => {
        let name = e.target.name;
        let value = e.target.value;
        await this.setState({
            [name]: value
        });


        if (name == "subject_id") {
            let params = {};

            if (value) {
                params = {
                    subject_id: value,
                    limit: 100,
                };
                await this.props.listChapter(params);
            }
        }

    };

    async componentDidMount() {
    }

    _handleEditorContentChange = (value, editor) => {
        this.setState({ content: value });
    };

    _uploadImageCallBack = async (blobInfo, success, failure) => {
        let file = blobInfo.blob();
        const data = new FormData();
        data.append("files", file);

        await this.props.uploadImage(data);

        if (this.props.image != null) {
            success(this.props.image);
        } else {
            failure("Upload image fail");
        }
    };

    onSubmit = (e) => {
    };

    fetchOptions() {
        if (this.props.books instanceof Array) {
            return this.props.books.map((obj, i) => {
                return <Option key={obj._id.toString()}>{obj.name}</Option>;
            });
        }
    }


    handleChange = async (e) => {
        var name = e.target.name;
        var value = e.target.value;
        await this.setState({
            [name]: value,
        });
        await this.props.listBook(this.getData());
    };

    async UNSAFE_componentWillReceiveProps(nextProps) {
        if (this.props.checkAll !== nextProps.check) {
            this.setState({
                checkAll: nextProps.check,
            });
        }
        if (this.props.selectedCateId !== nextProps.selectedCateId) {
            await this.props.showCategory(nextProps.selectedCateId, this.state.classroom_id);

            if (this.props.category) {
                let cateData = this.props.category;
                await this.setState({
                    id: cateData._id,
                    name: cateData.name ? cateData.name : "",
                    chapter_id: cateData.chapter ? cateData.chapter.id : "",
                    subject_id: cateData.subject ? cateData.subject.id : "",
                    content: cateData.content ? cateData.content : "",
                    is_free: cateData.is_free ? cateData.is_free : false,
                    free_started_at: cateData.created_at ? cateData.created_at : null,
                    free_finished_at: cateData.free_finished_at ? cateData.free_finished_at : null,
                    publish_at: cateData.publish_at ? cateData.publish_at : null,
                    exam_started_at: cateData.exam_started_at ? cateData.exam_started_at : null,
                    exam_finished_at: cateData.exam_finished_at ? cateData.exam_finished_at : null,
                    total_video_time: cateData.total_video_time ? cateData.total_video_time : 0,
                    videos: cateData.videos ? cateData.videos : [],
                    exam: cateData.exam ? cateData.exam : "",
                    exam_doc_link_1: cateData.exam_doc_link_1 ? cateData.exam_doc_link_1 : "",
                    exam_doc_link_2: cateData.exam_doc_link_2 ? cateData.exam_doc_link_2 : "",

                })
            }
        }
    }

    handleSubmit = async () => {
        let { id, name, chapter_id, exam_started_at, exam_finished_at, subject_id, content, is_free, free_started_at, publish_at, classroom_id, free_finished_at, total_video_time, videos, exam, exam_doc_link_1, exam_doc_link_2 } = this.state

        let data = {
            id,
            name,
            chapter_id,
            subject_id,
            classroom_id,
            content,
            is_free,
            free_started_at,
            free_finished_at,
            exam_started_at,
            exam_finished_at,
            publish_at,
            total_video_time,
            videos,
            exam_id: exam ? exam.id : null,
            exam_doc_link_1,
            exam_doc_link_2
        };


        await this.props.updateCategory(data);
    };

    fetchSubjects() {
        if (this.props.subjects instanceof Array) {
            return this.props.subjects.map((obj, i) => {
                return <option key={i} value={obj._id}>{obj.name}</option>;
            });
        }
    }

    fetchChapters() {
        if (this.props.chapters instanceof Array) {
            return this.props.chapters.map((obj, i) => {
                return <Option key={obj._id.toString()}>{obj.name}</Option>;
            });
        }
    }

    handleRemoveLesson = async (index) => {
        let listVideo = [...this.state.videos];

        listVideo.splice(index, 1);
        await this.setState({
            videos: listVideo
        })

    }

    deleteExam = async () => {
        await this.setState({
            exam: null
        })
    }

    changeDateStart = (date, dateString) => {
        if (date !== null) {
            this.setState({
                free_started_at: date.format("YYYY/MM/DD HH:mm"),
            });
        } else {
            this.setState({
                free_started_at: null,
            });
        }
    };

    changeDateEnd = (date, dateString) => {
        if (date !== null) {
            this.setState({
                free_finished_at: date.format("YYYY/MM/DD HH:mm"),
            });
        } else {
            this.setState({
                free_finished_at: null,
            });
        }
    };

    changePublishAt = (date, dateString) => {
        if (date !== null) {
            this.setState({
                publish_at: date.format("YYYY/MM/DD"),
            });
        } else {
            this.setState({
                publish_at: null,
            });
        }
    };

    changeStartedAt = (date, dateString) => {
        if (date !== null) {
            this.setState({
                exam_started_at: date.format("YYYY/MM/DD HH:mm"),
            });
        } else {
            this.setState({
                exam_started_at: null,
            });
        }
    };

    changeFinishedAt = (date, dateString) => {
        if (date !== null) {
            this.setState({
                exam_finished_at: date.format("YYYY/MM/DD HH:mm"),
            });
        } else {
            this.setState({
                exam_finished_at: null,
            });
        }
    };

    handleAddSelectedVideo = async () => {
        let { video_name, video_link, video_duration } = this.state;

        let data = {
            name: video_name,
            link: video_link,
            duration: video_duration
        };

        await this.handleAddVideo(data);


        await this.setState({
            video_name: "",
            video_link: "",
            video_duration: 0
        })
    }

    handleAddVideo = async (data) => {
        let listVideos = [...this.state.videos];
        if (data) {
            let objVideo = {
                ...data,
                ordering: listVideos.length + 1
            }
            listVideos.push(objVideo);
        }

        await this.setState({
            videos: listVideos
        })
    }

    fetchVideoRows() {
        if (this.state.videos instanceof Array) {
            return this.state.videos.map((object, index) => {
                return (
                    <Draggable
                        key={index}
                        draggableId={"" + index}
                        index={index}
                    >
                        {(provided, snapshot) => (
                            <tr
                                className="v-middle table-row-item"
                                data-id={17}
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                            >
                                <td>
                                    <img src="/assets/img/icon-move.svg" alt="" />
                                </td>
                                <td className="text-left">
                                    {object.name}
                                </td>
                                <td className="text-left">
                                    {object.link}
                                </td>

                                <td className="text-left">
                                    {object.duration}
                                </td>


                                <td className='text-right'>
                                    <div className="item-action">
                                        <a onClick={() => this.handleRemoveLesson(index)}>
                                            <img src="/assets/img/icon-delete.svg" alt="" />
                                        </a>
                                    </div>
                                </td>
                            </tr>
                        )}
                    </Draggable>
                );
            });
        }
    }

    reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    };

    onDragEndVideo = async (result) => {
        if (!result.destination) {
            return;
        }

        const items = this.reorder(
            this.state.videos,
            result.source.index,
            result.destination.index
        );

        await this.setState({
            videos: items,
        });
    }

    fetchOptionsExam = () => {
        if (this.props.exams instanceof Array) {
            return this.props.exams.map((obj, i) => {
                return <Option key={obj._id.toString()}>{obj.name}</Option>;
            });
        }
    }

    onChangeExam(val) {
        this.setState({
            exam_id: val
        })
    }

    onSearchExam = async (value) => {
        if (value) {
            await this.props.listExam({
                limit: 999,
                keyword: value,
                subject_id: this.state.subject_id,
            });
        }
    };
    handleListExam = async () => {
        const data = {
            limit: 20,
            subject_id: this.state.subject_id,
            is_delete: false,
        };
        await this.props.listExam(data);
    }

    handleAddSelectedExam = async () => {
        let { exams } = this.props;
        let exam_id = this.state.exam_id;

        if (exams) {
            let findata = exams.find(el => el._id === exam_id);

            if (findata) {
                let data = {
                    id: exam_id,
                    name: findata.name,
                    num_question: findata.questions ? findata.questions.length : 0
                }

                // await this.handleAddExam(data);

                await this.setState({
                    exam: data
                })
            }
        }
    }

    onSearchChapter = async (value) => {
        if (value) {
            await this.props.listChapter({
                limit: 999,
                keyword: value,
                subject_id: this.state.subject_id,
            });
        }
    };

    onChangeChapter(val) {
        this.setState({
            chapter_id: val
        })
    }


    render() {

        let settings = {
            dots: false,
            infinite: false,
            speed: 500,
            slidesToShow: 3,
            slidesToScroll: 1
        };

        return (
            <>
                <div
                    id="modal-edit-lesson"
                    className="modal fade modal-lesson book"
                    data-backdrop="true"
                    style={{
                        display: "none",
                        minWidth: "1000px",
                        zIndex: 1050
                    }}
                    aria-hidden="true"
                >
                    <div
                        className='modal-dialog animate fade-down modal-xl'
                        data-class='fade-down'
                        style={{
                            width: "900px"
                        }}
                    >
                        <div className='modal-content p-24'>
                            <div className='modal-body'>
                                <div className="block-content">
                                    <div className="block-info-lesson">
                                        <h3 className="title">Thông tin bài học</h3>
                                        <div className="input-item-flex">
                                            <div className="form-group w-100">
                                                <label className="text-form-label">Tên bài học</label>
                                                <div>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        name="name"
                                                        onChange={this.onChange}
                                                        value={this.state.name}
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="input-item-flex mt-24">
                                            <div className="form-group mr-16">
                                                <label className=" col-form-label">Miễn phí</label>
                                                <div>
                                                    <Radio.Group
                                                        onChange={this.onChange}
                                                        name="is_free"
                                                        value={this.state.is_free}
                                                    >
                                                        <Radio value={true}>Có</Radio>
                                                        <Radio value={false}>Không</Radio>
                                                    </Radio.Group>
                                                </div>
                                            </div>
                                            <div className="form-group mr-16">
                                                <label className=" col-form-label">Thời gian miễn phí</label>
                                                <div className="group-date" style={{ display: "flex" }}>
                                                    <DatePicker
                                                        format={
                                                            "YYYY/MM/DD HH:mm"
                                                        }
                                                        value={this.state.free_started_at
                                                            ? moment(this.state.free_started_at)
                                                            : null}
                                                        showTime={{ format: 'HH:mm' }}
                                                        placeholder="Từ ngày"
                                                        onChange={this.changeDateStart}
                                                    />
                                                    <DatePicker
                                                        format={
                                                            "YYYY/MM/DD HH:mm"
                                                        }
                                                        value={this.state.free_finished_at
                                                            ? moment(this.state.free_finished_at)
                                                            : null}
                                                        showTime={{ format: 'HH:mm' }}
                                                        placeholder="Đến ngày"
                                                        onChange={this.changeDateEnd}
                                                        className="ml-2"
                                                    />
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <label className=" col-form-label">Lượt xem tối đa</label>
                                                <div>
                                                    <input
                                                        type="number"
                                                        className="form-control"
                                                        name="total_video_time"
                                                        onChange={this.onChange}
                                                        value={this.state.total_video_time}
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="input-item-flex mt-24">
                                            <div className="form-group mr-16" style={{ width: "220px" }}>
                                                <label className=" col-form-label">Môn học</label>
                                                <div>
                                                    <select
                                                        className="custom-select"
                                                        value={
                                                            this.state.subject_id
                                                        }
                                                        name="subject_id"
                                                        onChange={this.onChange}>
                                                        <option value="">
                                                            -- Chọn môn học --
                                                        </option>
                                                        {this.fetchSubjects()}
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="form-group" style={{ width: "350px" }}>
                                                <label className=" col-form-label">Chương học</label>
                                                <div>
                                                    {/* <select
                                                        className="custom-select"
                                                        value={
                                                            this.state.chapter_id
                                                        }
                                                        name="chapter_id"
                                                        onChange={this.onChange}>
                                                        <option value="">
                                                            -- Chọn chương --
                                                        </option>
                                                        {this.fetchChapters()}
                                                    </select> */}

                                                    <Select
                                                        showSearch
                                                        placeholder="-- Chọn chương học -- "
                                                        optionFilterProp="children"
                                                        onChange={(val) => this.onChangeChapter(val)}
                                                        // onFocus={this.handleListExam}
                                                        onSearch={this.onSearchChapter}
                                                        name="chapter_id"
                                                        value={this.state.chapter_id}
                                                    >
                                                        {this.fetchChapters()}
                                                    </Select>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="input-item-flex mt-24">

                                            <div className="form-group" style={{ width: "50%", marginRight: "12px" }}>
                                                <label className="text-form-label">Link đề có đáp án</label>
                                                <div>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        name="exam_doc_link_1"
                                                        onChange={this.onChange}
                                                        value={this.state.exam_doc_link_1}
                                                    />
                                                </div>
                                            </div>

                                            <div className="form-group" style={{ width: "50%", marginLeft: "12px" }}>
                                                <label className="text-form-label">Link đề không có đáp án</label>
                                                <div>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        name="exam_doc_link_2"
                                                        onChange={this.onChange}
                                                        value={this.state.exam_doc_link_2}
                                                    />
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    <div className="block-attach-product p-0 mt-24">
                                        <div className="title-action">
                                            <h3 className="title-block mb-0 mr-18">Danh sách video</h3>
                                            {/* <button
                                                type="button"
                                                data-toggle="modal"
                                                data-target="#modal-add-video"
                                                data-toggle-class="fade-down"
                                                data-toggle-class-target=".animate"
                                            >
                                                <img src="/assets/img/icon-add-video.svg" alt="" className="mr-12" />
                                                Thêm video

                                            </button> */}
                                        </div>
                                        <div className="block-add-video">

                                            <div className="input-item-flex" style={{ alignItems: "flex-end", justifyContent: "space-between" }}>
                                                <div className="form-group mb-0 mr-16">
                                                    <label className="text-form-label">Tiêu đề</label>
                                                    <div>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            name="video_name"
                                                            onChange={this.onChange}
                                                            value={this.state.video_name}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="form-group mb-0 mr-16">
                                                    <label className="text-form-label">Link video</label>
                                                    <div>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            name="video_link"
                                                            onChange={this.onChange}
                                                            value={this.state.video_link}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="form-group mb-0 mr-16">
                                                    <label className="text-form-label">Thời gian (phút)</label>
                                                    <div>
                                                        <input
                                                            type="number"
                                                            className="form-control"
                                                            name="video_duration"
                                                            onChange={this.onChange}
                                                            value={this.state.video_duration}
                                                        />
                                                    </div>
                                                </div>
                                                <button type="button" className="btn-submit btn-add-video ml-16" onClick={this.handleAddSelectedVideo}>
                                                    Thêm video
                                                </button>
                                            </div>

                                        </div>

                                        <div className="list-videos-selected">
                                            <DragDropContext onDragEnd={this.onDragEndVideo}>
                                                <Droppable droppableId="droppable">
                                                    {(provided, snapshot) => (
                                                        <table className="table table-theme table-row v-middle" ref={provided.innerRef}>
                                                            <thead className="text-muted">
                                                                <tr>
                                                                    <th style={{ width: 10 }}></th>
                                                                    <th>Tên video</th>
                                                                    <th className="text-left">
                                                                        Link video
                                                                    </th>
                                                                    <th width="125px" className="text-left">
                                                                        Thời gian
                                                                    </th>
                                                                    <th className='text-right'>
                                                                        Thao tác
                                                                    </th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>{this.fetchVideoRows()}</tbody>
                                                            {provided.placeholder}
                                                        </table>
                                                    )}
                                                </Droppable>
                                            </DragDropContext>
                                        </div>
                                    </div>

                                    <div className="block-attach-product block-show-exam mt-24 p-0">
                                        <div className="title-action">
                                            <h3 className="title-block mb-0 mr-18">Đề thi</h3>
                                        </div>

                                        {
                                            !this.state.exam
                                            &&
                                            <div className="block-add-video">

                                                <div className="input-item-flex" style={{ alignItems: "flex-end", justifyContent: "space-between" }}>
                                                    <div className="form-group mb-0 mr-16" style={{ width: "100%" }}>
                                                        <label className="text-form-label">Tiêu đề</label>
                                                        <div>
                                                            <Select
                                                                showSearch
                                                                placeholder="-- Chọn đề thi -- "
                                                                optionFilterProp="children"
                                                                onChange={(val) => this.onChangeExam(val)}
                                                                onFocus={this.handleListExam}
                                                                onSearch={this.onSearchExam}
                                                                name="exam_id"
                                                            >
                                                                {this.fetchOptionsExam()}
                                                            </Select>
                                                        </div>
                                                    </div>
                                                    <button type="button" className="btn-submit btn-add-video ml-16" onClick={this.handleAddSelectedExam}>
                                                        Thêm đề thi
                                                    </button>
                                                </div>

                                            </div>
                                        }
                                        {
                                            this.state.exam && !isEmpty(this.state.exam)
                                            &&
                                            <div className="block-exam">
                                                <div className="action">
                                                    <a onClick={this.deleteExam}>
                                                        <img src="/assets/img/icon-close.svg" alt="" />
                                                    </a>
                                                </div>
                                                <div className="block-exam-info">
                                                    <div className="form-group">
                                                        <label className="col-form-label">Tên đề thi</label>
                                                        <span>{this.state.exam.name}</span>
                                                    </div>
                                                    <div className="form-group mt-16">
                                                        <label className="col-form-label">Số câu hỏi</label>
                                                        <span>{this.state.exam.num_question ? this.state.exam.num_question : 0}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    </div>

                                    <div className="block-description mt-24">
                                        <h3 className="title-block">Mô tả bài học</h3>
                                        <div className="content">
                                            <Editor
                                            apiKey={process.env.REACT_APP_API_KEY_TINY}
                                                onInit={(evt, editor) => {
                                                    this._handleEditorContentChange(
                                                        this.state.content,
                                                        editor
                                                    );
                                                }}
                                                value={this.state.content}
                                                init={{
                                                    height: 500,
                                                    menubar: false,
                                                    images_file_types:
                                                        "jpeg,jpg,jpe,jfi,jif,jfif,png,gif,bmp,webp",
                                                    plugins: [
                                                        "advlist autolink lists link image charmap print preview anchor",
                                                        "searchreplace visualblocks code fullscreen",
                                                        "insertdatetime media table paste code help wordcount tiny_mce_wiris",
                                                    ],
                                                    external_plugins: {
                                                        tiny_mce_wiris:
                                                            "https://www.wiris.net/demo/plugins/tiny_mce/plugin.js",
                                                    },
                                                    toolbar:
                                                        "undo redo | formatselect | " +
                                                        "bold italic backcolor | image | alignleft aligncenter " +
                                                        "alignright alignjustify | bullist numlist outdent indent | fontselect |  fontsizeselect |" +
                                                        "searchreplace visualblocks code fullscreen | " +
                                                        "lists link advlist insertdatetime media | tiny_mce_wiris_formulaEditor tiny_mce_wiris_formulaEditorChemistry | table paste code removeformat | help",
                                                    fontsize_formats:
                                                        "8pt 9pt 10pt 11pt 12pt 14pt 18pt 24pt 30pt 36pt 48pt 60pt 72pt 96pt",
                                                    content_style:
                                                        "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                                                    draggable_modal: true,
                                                    htmlAllowedTags: [".*"],
                                                    htmlAllowedAttrs: [".*"],
                                                    images_upload_handler: (
                                                        blobInfo,
                                                        success,
                                                        failure
                                                    ) =>
                                                        this._uploadImageCallBack(
                                                            blobInfo,
                                                            success,
                                                            failure
                                                        ),
                                                }}
                                                onEditorChange={
                                                    this._handleEditorContentChange
                                                }
                                            />
                                        </div>
                                    </div>
                                    {
                                        this.state.classroom_id
                                        &&
                                        <>
                                            <div className="input-item-flex mt-24">
                                                <div className="form-group" style={{ marginRight: "12px" }}>
                                                    <label className="text-form-label">Thời phát hành bài giảng</label>
                                                    <div>
                                                        <DatePicker
                                                            format={
                                                                "YYYY/MM/DD"
                                                            }
                                                            value={this.state.publish_at
                                                                ? moment(this.state.publish_at)
                                                                : null}
                                                            placeholder="Thời gian phát hành"
                                                            onChange={this.changePublishAt}
                                                            className="ml-2"
                                                        />
                                                    </div>
                                                </div>

                                                <div className="form-group" style={{ marginRight: "12px" }}>
                                                    <label className="text-form-label">Thời gian mở đề</label>
                                                    <div>
                                                        <DatePicker
                                                            format={
                                                                "YYYY/MM/DD HH:mm"
                                                            }
                                                            value={this.state.exam_started_at
                                                                ? moment(this.state.exam_started_at)
                                                                : null}
                                                            showTime={{ format: 'HH:mm' }}
                                                            placeholder="Thời gian mở đề"
                                                            onChange={this.changeStartedAt}
                                                            className="ml-2"
                                                        />
                                                    </div>
                                                </div>

                                                <div className="form-group" style={{ marginLeft: "12px" }}>
                                                    <label className="text-form-label">Thời gian đóng đề</label>
                                                    <div>
                                                        <DatePicker
                                                            format={
                                                                "YYYY/MM/DD HH:mm"
                                                            }
                                                            value={this.state.exam_finished_at
                                                                ? moment(this.state.exam_finished_at)
                                                                : null}
                                                            placeholder="Thời gian đóng đề"
                                                            showTime={{ format: 'HH:mm' }}
                                                            onChange={this.changeFinishedAt}
                                                            className="ml-2"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    }
                                </div>
                                <div className="block-action-footer">
                                    <button data-dismiss='modal' type="button" className="btn-cancel">
                                        <img src="/assets/img/icon-arrow-left.svg" alt="" className="mr-14" />
                                        Hủy thay đổi
                                    </button>
                                    <button type="button" className="btn-submit ml-16" onClick={this.handleSubmit}>
                                        Cập nhật
                                        <img src="/assets/img/icon-arrow-right.svg" alt="" className="ml-14" />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                {/* <ModalAddVideo handleAddVideo={this.handleAddVideo} /> */}
                {/* <ModalAddExam handleAddExam={this.handleAddExam} /> */}
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        subjects: state.subject.subjects,
        chapters: state.chapter.chapters,
        redirect: state.chapter.redirect,
        redirect_cate: state.category.redirect,
        image: state.question.image,
        category: state.category.category,
        exams: state.exam.exams,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            listSubject, createChapter, createCategory, uploadImage, showCategory, updateCategory, listExam, listChapter
        },
        dispatch
    );
}

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(ModalEditLesson)
);
